/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import californiaHero from '../../images/locationsHeroes/hero-california.jpg'
import womanLaptopEnroll from '../../images/people/woman-laptop-enroll.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import CobraBanner from '../../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/Footer'
import header from '../../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Apply for Aetna Medicare Plans in California | Call 833-998-1009',
          description:
            'Learn more about Aetna Medicare plans in California. Let us help you find the right plan and help answer any questions you might have. Call 833-998-1009',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/california',
        promoCode: '148719',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Apply for Aetna Medicare Plans in California | Call 833-998-1009',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={californiaHero}
                  alt="elderly couple holding hands on the beach and smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="light">
                    Aetna<sup>®</sup> Medicare, California{' '}
                  </Typography>

                  <Typography variant="h4" color="light">
                    Golden State coverage for your golden years{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Dreamin’ of great health coverage? Get Aetna Medicare in
                California
              </Typography>
              <Typography variant="body">
                When it comes to retirement, Florida gets all the attention. But
                when it comes to quality health care coverage during retirement,
                maybe Floridians should be California dreamin’. Why? Because
                California has more Medicare beneficiaries than any other US
                state. In fact, there were over{' '}
                <a
                  href="https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Total%22,%22sort%22:%22desc%22%7D"
                  target="_blank"
                >
                  6.2 million people enrolled in Medicare
                </a>{' '}
                in California in 2018. (That’s nearly two million more than the
                “Sunshine State.”)
              </Typography>
              <Typography variant="body">
                With offices from San Francisco to San Diego, we know Medicare
                in the Golden State. Read on to learn more about Medicare plans
                in California or speak with a licensed agent today to find plans
                near you!
              </Typography>
              <StaticImage
                src="../../images/illustrations/california-stats.svg"
                alt="over 6.2 million Californians enrolled in Mecicare plans"
                layout="fixed"
              />
            </>
          }
        ></VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-65.svg"
                alt="65 icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                A Medicare plan for every Californian
              </Typography>
              <Typography variant="body">
                There are a wide variety of Medicare plans in the state of
                California. Whether you’re looking for basic Original Medicare
                coverage, prescription drug coverage, or coverage with extra
                health and wellness benefits, you can find the plan you need at
                a rate you can afford near you.
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Original Medicare (Parts A and B)',
                      content: (
                        <>
                          <Typography>
                            Funded and administered by federal and California
                            state government, Original Medicare is made up of
                            two parts: Part A and Part B.{' '}
                            <a href="/parta">Medicare Part A</a>, also known as
                            “hospital insurance,” covers your inpatient care at
                            hospitals, skilled nursing facilities, or hospices.
                            Medicare Part B, on the other hand, is sometimes
                            called “medical insurance” and helps pay for your
                            Medicare-approved outpatient care. This includes
                            medical services and supplies such as doctor’s
                            visits, physical therapy, and preventative care. It
                            also covers durable medical equipment (DME) that you
                            may need, such as oxygen pumps, walkers, and
                            wheelchairs.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Advantage (Part C) plans in California',
                      content: (
                        <>
                          <Typography>
                            Unlike Original Medicare,{' '}
                            <a href="/advantage">Medicare Advantage plans</a>{' '}
                            are regulated by the federal government but are sold
                            by private insurance companies. By law, all Medicare
                            Advantage plans in California and all other states
                            must provide the same coverage as Original Medicare,
                            but most plans offer additional benefits coverage,
                            too. This may include things like vision, dental,
                            hearing aids, health and wellness programs, or gym
                            memberships. Some plans also include{' '}
                            <a href="/partd">prescription drug coverage</a>{' '}
                            (Medicare Part D). Added benefits vary according to
                            location, so talk to a licensed agent to find out
                            what’s available in your area.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medicare Part D (prescription drug) plans',
                      content: (
                        <>
                          <Typography>
                            If you’re enrolled in Part A and/or Part B, you can
                            choose to purchase a Medicare Part D separately.
                            Medicare Part D helps pay for the cost of your
                            prescription drugs and medications. Coverage for
                            specific drugs varies based on what’s called a
                            Medicare “formulary,” a list of drugs separated into
                            tiers according to price. Note, Medicare Part D
                            coverage may be included in some{' '}
                            <a href="/advantage">Medicare Part C</a> (Medicare
                            Advantage) plans.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-heart-plus.svg"
                alt="heart plus icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Surf through Medicare enrollment
              </Typography>
              <Typography variant="body">
                Enrolling in Medicare in California is quicker and easier than
                you might think. If you’re 65 years old and eligible for Social
                Security or Railroad Retirement Board benefits, you may even
                qualify for automatic enrollment!
              </Typography>
              <Typography variant="body">
                But what’s the fastest and simplest way to get enrolled? With
                help from a friendly agent licensed to enroll individuals in
                Medicare plans. Start your enrollment process today and talk to
                an agent!
              </Typography>
              <Typography variant="h4">
                Get enrolled in three simple steps:
              </Typography>
            </Column>
          </Columns>
          <Stack>
            <Columns>
              <Column className="card center-content">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Call a licensed agent at {rotatedNumber}
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Answer some questions about your coverage needs
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-card.svg"
                    alt="card icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Sign up for a plan
                  </Typography>
                </div>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-calendar.svg"
                alt="calendar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                When can you enroll in a Medicare Plan in California?
              </Typography>
              <Typography variant="body">
                <a href="/enrollment">Medicare enrollment periods</a> are
                divided up into three primary timeframes:
              </Typography>
              <Typography variant="h5">Initial Enrollment Period</Typography>
              <Typography variant="body">
                If you’re enrolling in Medicare for the first time, you can
                enroll in a Medicare plan during your Initial Enrollment Period.
                Your Initial Enrollment Period begins three months before the
                month of your 65th birthday and ends three months after your
                birth month.
              </Typography>
              <Typography variant="h5">Annual Enrollment Period</Typography>
              <Typography variant="body">
                If you’re already on Original Medicare and want to upgrade your
                coverage by switching to a{' '}
                <a href="/advantage">Medicare Advantage plan</a>, you can do so
                during the Annual Enrollment Period, which starts October 15 and
                ends December 7 every year.
              </Typography>
              <Typography variant="h5">Special Enrollment Period</Typography>
              <Typography variant="body">
                Under some circumstances, you may be able to enroll in a
                Medicare plan during a Special Enrollment Period. For example,
                you may be able to enroll in coverage or switch plans if you’ve
                recently moved or if you’re currently living in a nursing home.
                Talk to one of our licensed agents today to see if your{' '}
                <a
                  href="https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan/special-enrollment-periods"
                  target="_blank"
                >
                  special circumstance
                </a>{' '}
                applies.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Other Medicare Resources in California
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'California Department of Insurance',
                      content: (
                        <>
                          <Typography>
                            The{' '}
                            <a
                              href="http://www.insurance.ca.gov/0150-seniors/0300healthplans/"
                              target="_blank"
                            >
                              California Department of Insurance
                            </a>{' '}
                            provides a wide array of information and resources
                            regarding Medicare and senior health coverage.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Medi-Cal',
                      content: (
                        <>
                          <Typography>
                            Offered through the{' '}
                            <a
                              href="https://www.dhcs.ca.gov/Pages/default.aspx"
                              target="_blank"
                            >
                              California Department of Health Care Services
                            </a>
                            ,{' '}
                            <a
                              href="https://www.dhcs.ca.gov/services/medi-cal/Pages/default.aspx"
                              target="_blank"
                            >
                              Medi-Cal
                            </a>{' '}
                            is a program that may help pay for some of your
                            out-of-pocket medical costs, if you qualify for both
                            Medicare and Medicaid.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'California Medicare Savings Programs',
                      content: (
                        <>
                          <Typography>
                            There are a number of{' '}
                            <a
                              href="https://cahealthadvocates.org/low-income-help/medicare-savings-programs-msps/"
                              target="_blank"
                            >
                              Medicare Savings Programs
                            </a>{' '}
                            in California designed to help people with lower
                            incomes pay for some or all of their Medicare Part A
                            or B premiums.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title:
                        'California State Health Insurance and Assistance Program (SHIP)',
                      content: (
                        <>
                          <Typography>
                            Using federal grants, SHIP offers free one-on-one
                            counseling and other resources for Medicare
                            beneficiaries in California.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={womanLaptopEnroll}
              alt="an elderly woman enrolls in Medicare on her laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Plan in California today!
              </Typography>
              <Typography variant="body">
                Ready to Enroll in a California Medicare plan? Get started by
                tapping or clicking the button below. If you need help during
                the application process, simply contact a friendly, licensed
                agent at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
